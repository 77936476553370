import { defineStore } from "pinia"
import { computed, watch } from "vue"
import {
  minLength,
  required,
  minValue,
  decimal,
  requiredIf,
  between,
  sameAs,
} from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { useWizardStore } from "./wizardStore"
import useNewSimulationStore from "@/stores/newSimulationStore"

export const useWizardValidationStore = defineStore(
  "wizardValidationStore",
  () => {
    const wizardStore = useWizardStore()
    const newSimulationStore = useNewSimulationStore()
    /**
     * Simulation step validation rules
     */
    const simulationStepInvalid = computed(() => {
      return v$simulationRules.value.$invalid
    })
    const editSimulationRules = computed(() => ({
      newName: {
        required,
        minLength: minLength(3),
      },
    }))
    const v$editSimulationRules = useVuelidate(
      editSimulationRules.value,
      wizardStore,
    )

    const simulationRules = computed(() => ({
      simulation: {
        name: {
          required,
          minLength: minLength(3),
        },
        validName: {
          sameAs: sameAs(true),
        },
        simDescription: {
          required,
        },
        endDate: {
          required,
        },
      },
    }))
    const v$simulationRules = useVuelidate(simulationRules.value, wizardStore)

    /**
     * Connection step validation rules
     */
    const connectionStepInvalid = computed(() => {
      return v$connectionRules.value.$invalid
    })
    const connectionRules = computed(() => ({
      grid: {
        importCapacity: {
          required,
          decimal,
          minValue: minValue(0),
        },
        exportCapacity: {
          required,
          decimal,
          minValue: minValue(0),
        },
        operator: {
          required,
        },
      },
    }))
    const v$connectionRules = useVuelidate(connectionRules.value, wizardStore)

    /**
     * Data step validation rules
     */
    const dataStepInvalid = computed(() => {
      return v$dataRules.value.$invalid
    })
    const dataRules = computed(() => ({
      excel: {
        demandTable: {
          required: requiredIf(() => wizardStore.useExcelData),
        },
        columns: {
          required: requiredIf(() => wizardStore.useExcelData),
        },
      },
    }))
    const v$dataRules = useVuelidate(dataRules.value, wizardStore)

    /**
     * Consumption/Demand step validation rules
     */
    const consumptionStepInvalid = computed(() => {
      return v$consumptionRules.value.$invalid
    })
    const consumptionRules = computed(() => ({
      demand: {
        profile: {
          required,
        },
        yearly: {
          required,
          decimal,
          minValue: minValue(0),
        },
      },
    }))
    const v$consumptionRules = useVuelidate(consumptionRules.value, wizardStore)

    /**
     * Generate/Demand step validation rules
     */
    const generateStepInvalid = computed(() => {
      return v$generateRules.value.$invalid
    })

    const generateRules = computed(() => {
      return {
        solarParks: wizardStore.solarParks.map(() => ({
          orientation: {
            required,
          },
          AC: {
            required,
            decimal,
            minValue: minValue(0),
          },
          DC: {
            required,
            decimal,
            minValue: minValue(0),
          },
        })),
      }
    })
    const v$generateRules = useVuelidate(generateRules, wizardStore)

    /**
     * Calculation step validation rules
     */
    const calculationStepInvalid = computed(() => {
      return v$calculationRules.value.$invalid
    })

    const calculationRules = computed(() => ({
      uid: {
        required,
      },
      dataframes: {
        required,
      },
    }))
    const v$calculationRules = useVuelidate(
      calculationRules,
      newSimulationStore,
    )

    /**
     * Battery step validation rules
     */
    const batteryStepInvalid = computed(() => {
      return v$batteryRules.value.$invalid
    })

    const batteryRules = computed(() => ({
      battery: {
        capacity: {
          required,
          decimal,
          minValue: minValue(0),
        },
        power: {
          required,
          decimal,
          minValue: minValue(0),
        },
        technology: {
          required,
        },
        capex: {
          decimal,
          minValue: minValue(0),
        },
        eiaPercentage: {
          required,
          decimal,
          between: between(0, 1),
        },
        optimizationFeeFactor: {
          decimal,
          betweenPercentage: between(0, 100),
        },
      },
      strategy: {
        required,
      },
    }))
    const v$batteryRules = useVuelidate(batteryRules, wizardStore)

    /**
     * All steps validation rules
     */
    const disabledSteps = computed(() => {
      const disabled = {
        [4]:
          !wizardStore.simulation.simDescription?.includes("verbruik") ||
          (wizardStore.useExcelData && wizardStore.excel.columns !== "BPM"),
        [5]:
          !wizardStore.simulation.simDescription?.includes("opwek") ||
          (wizardStore.useExcelData &&
            wizardStore.excel.columns !== "LDN" &&
            wizardStore.excel.columns !== "ODN, LDN"),
      }
      disabled[6] = [
        !simulationStepInvalid.value,
        !connectionStepInvalid.value,
        !dataStepInvalid.value,
        !consumptionStepInvalid.value || disabled[4],
        !generateStepInvalid.value || disabled[5],
      ].some((step) => !step)
      return disabled
    })

    /**
     * All steps validation computed property
     */
    const allStepsValid = computed(() => {
      const stepOne = !simulationStepInvalid.value
      const stepTwo = !connectionStepInvalid.value
      const stepThree = !dataStepInvalid.value && v$dataRules.value.$dirty
      const stepFour = !consumptionStepInvalid.value
      const stepFive = !generateStepInvalid.value
      const stepSix = !calculationStepInvalid.value
      const stepSeven = !batteryStepInvalid.value

      const stepFourDisabled = disabledSteps.value[4]
      const stepFiveDisabled = disabledSteps.value[5]

      return (
        stepOne &&
        stepTwo &&
        stepThree &&
        (stepFourDisabled || stepFour) &&
        (stepFiveDisabled || stepFive) &&
        stepSix &&
        stepSeven
      )
    })

    const validationRules = {
      1: v$simulationRules,
      2: v$connectionRules,
      3: v$dataRules,
      4: v$consumptionRules,
      5: v$generateRules,
      6: v$calculationRules,
      7: v$batteryRules,
    }

    watch(
      () => wizardStore.currentStep,
      (newValue, oldValue) => {
        if (newValue === 7 && !newSimulationStore.dataframes) {
          validationRules[newValue]?.value.$reset()
        } else {
          validationRules[oldValue]?.value.$validate()
        }
      },
    )

    return {
      simulationRules,
      simulationStepInvalid,
      v$simulationRules,
      connectionRules,
      connectionStepInvalid,
      v$connectionRules,
      dataRules,
      dataStepInvalid,
      v$dataRules,
      consumptionRules,
      consumptionStepInvalid,
      v$consumptionRules,
      generateStepInvalid,
      generateRules,
      v$generateRules,
      calculationStepInvalid,
      calculationRules,
      v$calculationRules,
      batteryStepInvalid,
      v$batteryRules,
      batteryRules,
      allStepsValid,
      disabledSteps,
      validationRules,
      editSimulationRules,
      v$editSimulationRules,
    }
  },
)
