<template>
  <div>
    <div class="mb-4 flex items-baseline justify-between">
      <TextHeader class="!border-0">Resultaten</TextHeader>
      <PdfMaker />
    </div>
    <LoadingWrapper
      :loading="resultsStore.loading"
      :error="resultsStore.loadingFailed"
    >
      <div class="grid grid-cols-12 gap-6">
        <div class="sidebar col-span-3">
          <BaseCard class="p-6">
            <div class="flex">
              <TextLabel>Omschrijving</TextLabel>
            </div>

            <BaseCard
              v-for="(item, index) in sideBarItems"
              :key="index"
              class="mt-4 overflow-hidden"
            >
              <BaseTable :columns="item.columns" :rows="item.rows">
                <template v-if="!demoStore.simulation_quotum_exceeded" #value>
                  <RouterLink
                    :to="{
                      name: 'wizard-edit',
                      params: { uid: resultsStore.results.uid },
                      query: { step: item.step },
                    }"
                  >
                    <BaseButton color="subtle" square small class="-m-2">
                      <BaseIcon name="pencil" filled />
                    </BaseButton>
                  </RouterLink>
                </template>
              </BaseTable>
            </BaseCard>

            <RouterLink
              class="mt-4 block"
              :to="{
                name: 'wizard-edit',
                params: { uid: resultsStore.results.uid },
              }"
            >
              <component
                :is="demoStore.simulation_quotum_exceeded ? BaseTooltip : 'div'"
                description="Maximaal aantal simulaties voor deze demo bereikt"
                class="w-full"
              >
                <BaseButton
                  color="secondary"
                  :disabled="demoStore.simulation_quotum_exceeded"
                  class="w-full border-gray-900 text-sm"
                >
                  <BaseIcon class="mr-2" name="pencil" :filled="true" />
                  Wijzig alle gegevens
                </BaseButton>
              </component>
            </RouterLink>

            <p class="mt-2 text-xs text-gray-400">
              Bij een wijziging wordt de gehele simulatie opnieuw gedraaid.
            </p>
          </BaseCard>
        </div>
        <div class="col-span-9">
          <ResultsConclusion />

          <BaseDivider class="my-6" />

          <ResultsBattery />

          <BaseDivider class="my-6" />

          <ResultsFinance />

          <BaseDivider class="my-6" />

          <ResultsEnergeticSituation />

          <BaseDivider class="my-6" />

          <ResultsAssumptions v-if="resultsStore.assumptions" />

          <BaseDivider class="my-6" />
        </div>
      </div>
    </LoadingWrapper>
  </div>
</template>

<script setup>
import { computed } from "vue"
import { useRoute } from "vue-router"
import { toDecimal } from "@/helpers/currency-helper"
import { useResultsStore } from "@/stores/useResultsStore"
import {
  BaseCard,
  TextHeader,
  BaseDivider,
  TextLabel,
  BaseTable,
  BaseButton,
  BaseIcon,
  LoadingWrapper,
  BaseTooltip,
} from "@repowerednl/ui-component-library"
import ResultsConclusion from "@/components/results/ResultsConclusion.vue"
import ResultsBattery from "@/components/results/ResultsBattery.vue"
import ResultsFinance from "@/components/results/ResultsFinance.vue"
import ResultsAssumptions from "@/components/results/ResultsAssumptions.vue"
import ResultsEnergeticSituation from "@/components/results/ResultsEnergeticSituation.vue"
import PdfMaker from "@/components/PdfMaker.vue"
import { useDemoStore } from "@/stores/useDemoStore"
import { formatNumber } from "@/services/formatterService"

const demoStore = useDemoStore()
const resultsStore = useResultsStore()
const route = useRoute()

/**
 * Fetch the data from the store.
 */
resultsStore.getResult(route.params.uid)

const sideBarItems = computed(() => [
  {
    step: 5,
    columns: {
      key: { label: "Opgesteld vermogen opwek" },
      value: { label: "" },
    },
    rows: [
      {
        key: "PV-installatie",
        value: resultsStore.results.simulation_summary.total_solar_park_size
          ? `${toDecimal(resultsStore.results.simulation_summary.total_solar_park_size)} kWp`
          : "-",
      },
    ],
  },
  {
    step: 4,
    columns: {
      key: { label: "Totaal verbruik" },
      value: { label: "" },
    },
    rows: [
      {
        key: "Jaarverbruik",
        value: resultsStore.results.simulation_summary
          ? `${toDecimal(resultsStore.results.simulation_summary.demand_yearly)} MWh`
          : "-",
      },
    ],
  },
  {
    step: 2,
    columns: {
      key: { label: "Elektriciteitsaansluiting" },
      value: { label: "" },
    },
    rows: [
      {
        key: "Gecontracteerde teruglevering",
        value: resultsStore.results.simulation_summary?.grid_import
          ? `${toDecimal(resultsStore.results.simulation_summary.grid_import)} kW`
          : "-",
      },
      {
        key: "Gecontracteerde afname",
        value: resultsStore.results.simulation_summary.grid_export
          ? `${toDecimal(resultsStore.results.simulation_summary.grid_export)} kW`
          : "-",
      },
      {
        key: "Netbeheerder",
        value: resultsStore.results.simulation_summary.grid_operator ?? "-",
      },
    ],
  },
  {
    step: 7,
    columns: {
      key: { label: "Batterij" },
      value: { label: "" },
    },
    rows: [
      {
        key: "Beschikbaarheid van de aansluiting voor levering",
        value:
          resultsStore.results.simulation_summary?.offtake_availability >= 0
            ? formatNumber(
                resultsStore.results.simulation_summary.offtake_availability,
                0,
              ) + "%"
            : "-",
      },
      {
        key: "Beschikbaarheid van de aansluiting voor teruglevering",
        value:
          resultsStore.results.simulation_summary?.inflow_availability >= 0
            ? formatNumber(
                resultsStore.results.simulation_summary.inflow_availability,
                0,
              ) + "%"
            : "-",
      },
      {
        key: "Capaciteit",
        value: resultsStore.results.simulation_summary?.battery_capacity
          ? `${toDecimal(resultsStore.results.simulation_summary.battery_capacity)} KWh`
          : "-",
      },
      {
        key: "Vermogen",
        value: resultsStore.results.simulation_summary?.battery_power
          ? `${toDecimal(resultsStore.results.simulation_summary.battery_power)} kW`
          : "-",
      },
      {
        key: "Technologie",
        value:
          resultsStore.results.simulation_summary?.battery_technology ?? "-",
      },
      {
        key: "Strategie",
        value: resultsStore.results.simulation_summary?.strategy ?? "-",
      },
    ],
  },
])
</script>
<style lang="postcss" scoped>
.sidebar {
  table {
    ::v-deep(tr) {
      @apply flex items-center justify-between border-b border-gray-100;

      th {
        @apply border-0;
      }

      td {
        @apply border-0;

        &:first-child {
          @apply whitespace-break-spaces;
        }
      }
    }
  }
}
</style>
